import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainApp from "./layouts/MainApp";
import Family from "./pages/Family";
import FamilyCreate from "./pages/Family/Create";
import FamilyUpdate from "./pages/Family/Update";
import Finance from "./pages/Finance";
import FinanceMerchant from "./pages/Finance/Merchant";
import Home from "./pages/Home";
import Need from "./pages/Need";
import NeedCreate from "./pages/Need/Create";
import NeedUpdate from "./pages/Need/Update";
import Order from "./pages/Order";
import OrderDetail from "./pages/Order/Detail";
import Payment from "./pages/Payment";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="" element={<MainApp />}>
            <Route index element={<Home />} />
            <Route path="finance">
              <Route index element={<Finance />} />
              <Route path="merchant/:id" element={<FinanceMerchant />} />
            </Route>
            <Route path="order">
              <Route index element={<Order />} />
              <Route path=":id" element={<OrderDetail />} />
            </Route>
            <Route path="payment/order/:orderId" element={<Payment />} />
            <Route path="need">
              <Route index element={<Need />} />
              <Route path="create" element={<NeedCreate />} />
              <Route path=":id/update" element={<NeedUpdate />} />
              <Route path=":needId/family" element={<Family />} />
              <Route path=":needId/family/create" element={<FamilyCreate />} />
              <Route
                path=":needId/family/:familyId/update"
                element={<FamilyUpdate />}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
