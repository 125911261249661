import { useSelector } from "react-redux";
import { success } from "../../../redux/action/order";
import Gap from "../../atoms/Gap";

const SectionForm = () => {
  const { order } = useSelector((state) => state.order);

  const btnSuccess = (id) => {
    success(id)
      .then((res) => {
        alert(res.message);
        window.location.reload();
      })
      .catch((err) => alert(err.message));
  };

  return (
    <div className="card" style={styles.card}>
      <div style={styles.title}>Bukti Pembayaran</div>
      <Gap height={10} />
      <div className="center">
        <img
          src={`//${order.proofOfPayment}`}
          className="responsive-img"
          style={styles.image}
        />
      </div>
      <div
        className="center"
        onClick={() => {
          btnSuccess(order.id);
        }}
      >
        <button className="btn">Acc Pembayaran</button>
      </div>
    </div>
  );
};

export default SectionForm;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  title: { fontSize: 16 },
  image: { width: 150 },
};
