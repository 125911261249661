import M from "materialize-css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import SectionAmount from "../../../components/organisms/Finance/Merchant/SectionAmount";
import SectionFilter from "../../../components/organisms/Finance/Merchant/SectionFilter";
import SectionOrders from "../../../components/organisms/Finance/Merchant/SectionOrders";
import { getIncome } from "../../../redux/action/order";

const FinanceMerchant = () => {
  useEffect(() => {
    var elemsSelect = document.querySelectorAll("select");
    M.FormSelect.init(elemsSelect, {});
  }, []);

  const { id } = useParams();

  let [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getIncome(id, status ? status : "")
      .then((res) => {
        dispatch({
          type: "GET_INCOME",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <div className="container">
      <SectionFilter id={id} status={status} />
      <SectionAmount id={id} status={status} />
      <SectionOrders status={status} />
      <Link to="/finance" className="btn grey" style={styles.btn}>
        <i className="material-icons">arrow_back</i>
      </Link>
    </div>
  );
};

export default FinanceMerchant;

const styles = {
  btn: { borderRadius: 10 },
  loading: {},
};
