import { useState } from "react";
import { useSelector } from "react-redux";
import Gap from "../../../atoms/Gap";
import { Link } from "react-router-dom";

const SectionOrders = ({ status }) => {
  const { income } = useSelector((state) => state.income);

  const [selectedOrders, setSelectedOrders] = useState([]);

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = e.target.value;

    if (isSelected) {
      setSelectedOrders([...selectedOrders, value]);
    } else {
      setSelectedOrders((prevData) => {
        return prevData.filter((id) => {
          return id !== value;
        });
      });
    }
  };

  const checkAllHandler = () => {
    if (income.Orders.length === selectedOrders.length) {
      setSelectedOrders([]);
    } else {
      const postIds = income.Orders.map((value) => {
        return value.id;
      });

      setSelectedOrders(postIds);
    }
  };

  const btnWithdraw = () => {};

  if (status === "pending" || status === "withdraw") {
    return (
      <div className="card" style={styles.card}>
        <div style={styles.title}>Rincian</div>
        <Gap height={10} />
        {income.Orders?.map((value, index) => (
          <Link
            to={`/order/${value.id}`}
            style={styles.spaceBetween}
            key={index}
          >
            <div style={styles.id}>{value.id}</div>
            <div style={styles.totalPrice}>{value.totalPrice}</div>
          </Link>
        ))}
      </div>
    );
  }

  if (status === "success") {
    return (
      <div className="card" style={styles.card}>
        <div style={styles.spaceBetween}>
          {income.Orders.length === selectedOrders.length ? (
            <button
              className="btn red"
              onClick={checkAllHandler}
              style={styles.btn}
            >
              <i className="material-icons">clear</i>
            </button>
          ) : (
            <button
              className="btn"
              onClick={checkAllHandler}
              style={styles.btn}
            >
              <i className="material-icons">check</i>
            </button>
          )}
          <button className="btn" style={styles.btn}>
            Withdraw
          </button>
        </div>
        {income.Orders?.map((value, index) => (
          <p key={index}>
            <label>
              <input
                type="checkbox"
                checked={selectedOrders.includes(value.id)}
                value={value.id}
                onChange={checkboxHandler}
              />
              <span>
                <div className="valign-wrapper">
                  <div style={styles.id}>{value.id}</div>
                  <div style={styles.totalPrice}>{value.totalPrice}</div>
                </div>
              </span>
            </label>
          </p>
        ))}
      </div>
    );
  }
};

export default SectionOrders;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  title: {
    fontSize: 16,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    borderRadius: 10,
  },
  id: {
    fontSize: 16,
    marginRight: 10,
  },
  totalPrice: {
    fontSize: 16,
  },
};
