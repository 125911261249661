import Api from "../../../api/Api";

const getOrders = (status) => {
  return new Promise((resolve, reject) => {
    Api.get(`/admin-orders?status=${status}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getOrder = (id) => {
  return new Promise((resolve, reject) => {
    Api.get(`/admin-orders/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const success = (id) => {
  return new Promise((resolve, reject) => {
    Api.put(`/admin-orders/${id}/success`, {})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const setDriver = (id, form) => {
  return new Promise((resolve, reject) => {
    Api.put(`/admin-orders/${id}/set-driver`, form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getIncome = (id, status, startDate = "", endDate = "") => {
  return new Promise((resolve, reject) => {
    Api.get(
      `/admin-orders/merchants/${id}/income?status=${status}&startDate=${startDate}&endDate=${endDate}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { getIncome, getOrder, getOrders, setDriver, success };
