import Api from "../../../api/Api";

const getMerchants = (search = "") => {
  return new Promise((resolve, reject) => {
    Api.get(`/admin-merchants?search=${search}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { getMerchants };
