import { useSelector } from "react-redux";

const SectionInstruction = () => {
  const { order } = useSelector((state) => state.order);

  return (
    <div className="card" style={styles.card}>
      <ol style={styles.ol}>
        <li>
          <div>
            Silahkan lakukan pembayaran sebesar <b>{order.totalPayment}</b>{" "}
          </div>
          <div>
            No. Rek <b>{order.paymentMethod}</b>
          </div>
        </li>
        <li>Unggah gambar/foto bukti pembayaran anda dibawah ini</li>
      </ol>
    </div>
  );
};

export default SectionInstruction;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  ol: { margin: 0, fontSize: 16 },
};
