import Api from "../../../api/Api";

const getNeeds = () => {
  return new Promise((resolve, reject) => {
    Api.get("/admin-needs")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const storeNeed = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/admin-needs", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getNeed = (id) => {
  return new Promise((resolve, reject) => {
    Api.get(`/admin-needs/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateNeed = (id, form) => {
  return new Promise((resolve, reject) => {
    Api.put(`/admin-needs/${id}`, form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const destroyNeed = (id) => {
  return new Promise((resolve, reject) => {
    Api.delete(`/admin-needs/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { destroyNeed, getNeed, getNeeds, storeNeed, updateNeed };
