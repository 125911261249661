import M from "materialize-css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import ModalDriver from "../../components/organisms/Order/ModalDriver";
import SectionFilter from "../../components/organisms/Order/SectionFilter";
import SectionOrder from "../../components/organisms/Order/SectionOrder";
import { getOrders } from "../../redux/action/order";

const Order = () => {
  useEffect(() => {
    var elemsSelect = document.querySelectorAll("select");
    M.FormSelect.init(elemsSelect, {});
    var elemsModal = document.querySelectorAll(".modal");
    M.Modal.init(elemsModal, {});
  });

  let [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getOrders(status ? status : "")
      .then((res) => {
        dispatch({
          type: "GET_ORDERS",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [orderId, setOrderId] = useState();

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <SectionFilter status={status} />
        <SectionOrder setOrderId={setOrderId} />
        <Link to="/" className="btn grey" style={styles.btn}>
          <i className="material-icons">arrow_back</i>
        </Link>
      </div>
      <ModalDriver orderId={orderId} />
    </>
  );
};

export default Order;

const styles = {
  btn: { borderRadius: 10 },
  loading: {},
};
