import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";
import { getFamily, updateFamily } from "../../../redux/action/family";

const FamilyUpdate = () => {
  const { needId } = useParams();
  const { familyId } = useParams();

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    getFamily(familyId)
      .then((res) => {
        setName(res.data.name);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();

  const formFamily = (e) => {
    e.preventDefault();
    setLoading(true);

    const form = {
      name,
    };

    updateFamily(familyId, form)
      .then((res) => {
        toast(res.message);
        navigate(`/need/${needId}/family`);
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          toast(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <div className="container">
      <form className="card" onSubmit={formFamily} style={styles.card}>
        <div className="input-field">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label className="active">Nama</label>
          {validation?.map((value, index) =>
            value.field === "name" ? (
              <span className="helper-text red-text" key={index}>
                {value.message}
              </span>
            ) : null
          )}
        </div>
        <div style={styles.spaceBetween}>
          <Link
            to={`/need/${needId}/family`}
            className="btn grey"
            style={styles.btn}
          >
            <i className="material-icons">arrow_back</i>
          </Link>
          <button className="btn" style={styles.btn}>
            <i className="material-icons">save</i>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FamilyUpdate;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  spaceBetween: { display: "flex", justifyContent: "space-between" },
  btn: { borderRadius: 10 },
  loading: {},
};
