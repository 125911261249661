const stateDrivers = {
  drivers: [],
};

const drivers = (state = stateDrivers, action) => {
  if (action.type === "GET_DRIVERS") {
    return {
      ...state,
      drivers: action.payload,
    };
  }

  return state;
};

export { drivers };
