const stateNeeds = {
  needs: [],
};

const stateNeed = {
  need: {},
};

const needs = (state = stateNeeds, action) => {
  if (action.type === "GET_NEEDS") {
    return {
      ...state,
      needs: action.payload,
    };
  }

  return state;
};

const need = (state = stateNeed, action) => {
  if (action.type === "GET_NEED") {
    return {
      ...state,
      need: action.payload,
    };
  }

  return state;
};

export { needs, need };
