import { useSelector } from "react-redux";
import Gap from "../../atoms/Gap";
import { setDriver } from "../../../redux/action/order";

const ModalDriver = ({ orderId }) => {
  const { drivers } = useSelector((state) => state.drivers);

  const btnSetDriver = (driverId) => {
    const form = {
      driverId,
    };

    setDriver(orderId, form)
      .then((res) => {
        alert(res.message);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div id="modal1" className="modal">
      <div className="modal-content">
        {drivers.map((value, index) => (
          <>
            <div key={index}>
              {value.isDelivery ? (
                <div style={styles.container}>
                  <div>
                    {value.name} - {value.phone}
                  </div>
                  <i className="material-icons">local_shipping</i>
                </div>
              ) : (
                <div style={styles.container}>
                  <div>
                    {value.name} - {value.phone}
                  </div>
                  <button
                    className="btn"
                    style={styles.btn}
                    onClick={() => btnSetDriver(value.id)}
                  >
                    Set Driver
                  </button>
                </div>
              )}{" "}
            </div>
            <Gap height={10} />
          </>
        ))}
      </div>
    </div>
  );
};

export default ModalDriver;

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn: {
    borderRadius: 10,
  },
};
