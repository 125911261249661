import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";
import Gap from "../../components/atoms/Gap";
import { destroyFamily, getFamilies } from "../../redux/action/family";

const Family = () => {
  const { needId } = useParams();

  const [loading, setLoading] = useState(true);

  const { families } = useSelector((state) => state.families);

  const dispatch = useDispatch();

  useEffect(() => {
    getFamilies(needId)
      .then((res) => {
        dispatch({
          type: "GET_FAMILIES",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const btnDelete = (id) => {
    setLoading(true);
    destroyFamily(id)
      .then((res) => {
        toast(res.message);
      })
      .then(() => {
        getFamilies(needId).then((res) => {
          dispatch({
            type: "GET_FAMILIES",
            payload: res.data,
          });
        });
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <Gap height={10} />
        <div className="valign-wrapper">
          <Link to="/need" className="btn grey" style={styles.btn}>
            <i className="material-icons">arrow_back</i>
          </Link>
          <Link
            to={`/need/${needId}/family/create`}
            className="btn"
            style={styles.btn}
          >
            <i className="material-icons">add</i>
          </Link>
        </div>
        <Gap height={5} />
        <div style={styles.need}>{families[0]?.Need.name}</div>
        {families.map((value, index) => (
          <div className="card" style={styles.card} key={index}>
            <div style={styles.flex}>
              <div style={styles.name}>{value.name}</div>
            </div>
            <div style={styles.right}>
              <div style={styles.flex}>
                <Link
                  to={`/need/${needId}/family/${value.id}/update`}
                  className="btn orange"
                  style={styles.btn}
                >
                  <i className="material-icons">edit</i>
                </Link>
                <button
                  className="btn red"
                  style={styles.btn}
                  onClick={() => btnDelete(value.id)}
                >
                  <i className="material-icons">delete</i>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Gap height={70} />
    </>
  );
};

export default Family;

const styles = {
  btn: { borderRadius: 10, marginRight: 2 },
  need: { fontSize: 16 },
  card: {
    padding: 10,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
  },
  flex: { display: "flex", alignItems: "center", width: "100%" },
  name: { fontSize: 16 },
  right: { marginLeft: "auto", marginRight: -2 },
  loading: {},
};
