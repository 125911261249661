import Api from "../../../api/Api";

const getFamilies = (needId) => {
  return new Promise((resolve, reject) => {
    Api.get(`/admin-families/needs/${needId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const storeFamily = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/admin-families", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getFamily = (id) => {
  return new Promise((resolve, reject) => {
    Api.get(`/admin-families/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateFamily = (id, form) => {
  return new Promise((resolve, reject) => {
    Api.put(`/admin-families/${id}`, form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const destroyFamily = (id) => {
  return new Promise((resolve, reject) => {
    Api.delete(`/admin-families/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { destroyFamily, getFamilies, getFamily, storeFamily, updateFamily };
