const stateMerchants = {
  merchants: [],
};

const merchants = (state = stateMerchants, action) => {
  if (action.type === "GET_MERCHANTS") {
    return {
      ...state,
      merchants: action.payload,
    };
  }

  return state;
};

export { merchants };
