import { combineReducers } from "redux";
import { needs, need } from "./need";
import { families, family } from "./family";
import { orders, order, income } from "./order";
import { drivers } from "./driver";
import { merchants } from "./merchant";

const reducer = combineReducers({
  needs,
  need,
  families,
  family,
  orders,
  order,
  income,
  drivers,
  merchants,
});

export default reducer;
