import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLoader, SyncLoader } from "react-spinners";
import { getMerchants } from "../../redux/action/merchant";

const Finance = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState();

  const { merchants } = useSelector((state) => state.merchants);

  useEffect(() => {
    setLoading(false);
  }, []);

  const dispatch = useDispatch();

  const formSearch = (e) => {
    e.preventDefault();
    setLoading(true);

    getMerchants(search)
      .then((res) => {
        dispatch({
          type: "GET_MERCHANTS",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <div className="container">
      <form className="card" onSubmit={formSearch} style={styles.card}>
        <div className="valign-wrapper">
          <input
            type="text"
            placeholder="Cari merchant..."
            style={styles.input}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <button className="btn" style={styles.btn}>
            <i className="material-icons">search</i>
          </button>
        </div>
      </form>
      {loading ? (
        <div className="center">
          <SyncLoader color="#ff9100" />
        </div>
      ) : (
        merchants.map((value, index) => (
          <a href={`/finance/merchant/${value.id}?status=pending`} key={index}>
            <div
              className="card valign-wrapper grey-text text-darken-3"
              style={styles.card}
              key={index}
            >
              <img
                src={`//${value.image}`}
                className="responsive-img"
                style={styles.image}
              />
              <div style={styles.merchant}>{value.name}</div>
            </div>
          </a>
        ))
      )}
      <Link to="/" className="btn grey" style={styles.btn}>
        <i className="material-icons">arrow_back</i>
      </Link>
    </div>
  );
};

export default Finance;

const styles = {
  input: { marginRight: 10 },
  btn: { borderRadius: 10 },
  card: { padding: 10, borderRadius: 10 },
  image: { width: 70, height: 70, marginRight: 10 },
  merchant: { fontSize: 16 },
  loading: {},
};
